import React from "react";
import { Link } from "gatsby";

import phone from "../../images/Global/Nav/Utility Phone number.svg";
import payment from "../../images/Global/Nav/Utility Pay Bill.svg";
import signIn from "../../images/Global/Nav/Utility Client Login.svg";

const UtilityNav = ({ navigationStyle, pageType }) => {
  return (
    pageType !== "Landing" && (
      <div
        id="utlity-navigation"
        className={`hidden bg-black py-1.5 lg:block ${
          navigationStyle === "overlap" ? "" : ""
        }`}
      >
        <div className="container">
          <div className="flex items-center justify-between">
            <div>
              <a
                href="tel:301-609-7515"
                target="_blank"
                rel="noreferrer"
                className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
              >
                <img src={phone} width={20} alt="Phone" className="mr-2" />
                (301) 609-7515
              </a>
            </div>
            <div className="flex items-center space-x-10">
              <div>
                <a
                  href="https://www.cbh.com/client-hub/"
                  target="_blank"
                  className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
                >
                  <img src={payment} width={24} alt="Pay Your Bill" />
                  <span>Pay Your Bill</span>
                </a>
              </div>
              <div>
                <a
                  href="https://www.cbh.com/client-hub/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center space-x-1.5 text-sm font-bold text-white no-underline hover:text-white"
                >
                  <img src={signIn} width={14} alt="Login" />
                  <span>Client Portal </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default UtilityNav;
